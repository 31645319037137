<template>
    <div :class="{ item: 1, active }" @mouseenter="setActive" @mouseleave="setActive"
        :style="{ backgroundImage: `url(${item.img})` }" @click="openVideo">
        <video ref="video" :class="{ prev: true, active }" :src="item.prevUrl" muted loop playsinline
            :autoplay="active"></video>
        <h3 class="title">{{ item.title }}</h3>
        <div class="content">
            <h3 class="content__title">{{ item.title }}</h3>
            <p class="content__text">{{ item.text }}</p>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
    props: {
        item: Object
    },
    setup(props) {
        const store = useStore()
        const active = ref(false)
        const video = ref(null)
        const setActive = () => {
            active.value = !active.value
            active.value ? video.value.play() : video.value.pause()
        }

        const openVideo = () => {
            store.commit("setModal", { type: 'video', url: props.item.video });
        };
        return { ...props, setActive, active, video, openVideo }
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.item {
    height: 50vh;
    width: 40vh;
    flex-basis: 20%;
    flex: 0 1 20%;
    background-size: cover;
    background-position: center center;
    transition: all 330ms cubic-bezier(0.54, 0.22, 0.16, 0.78), transform 330ms cubic-bezier(0.54, 0.22, 0.16, 0.78) 160ms;
    display: flex;
    align-items: flex-end;
    position: relative;
    cursor: pointer;


    @include break($large) {
        //height: vw(364);
    }

    @include break($medium) {
        //height: vw(364, 1200);
    }

    &:hover {
        flex-basis: 72%;
    }

    &.active {
        transform: scale(1.05);
        z-index: 1;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 224px;
        opacity: 0.58;
        background-image: -webkit-gradient(linear,
                left top,
                left bottom,
                from(rgba(0, 43, 112, 0)),
                to(#002b70));
        background-image: linear-gradient(to bottom,
                rgba(0, 43, 112, 0) 0,
                #002b70 100%);


    }

    .title {
        z-index: 5;
        display: inline-block;
        font-size: vw(25);
        line-height: 1.2;
        font-weight: bold;
        padding: vw(18);
        color: #fff;
        flex-basis: 40%;
        transition: opacity 180ms linear;
    }
}

.prev {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    opacity: 0;
    transition: opacity 180ms linear;

    &.active {
        display: block;
        opacity: 1;

        &~.title {
            opacity: 0;
        }

        &~.content {
            visibility: visible;
            background-color: #fff;
            box-shadow: -10px 20px 50px 0 rgb(91 121 171 / 30%);

            .content__title,
            .content__text {
                opacity: 1;
                display: block;
                transform: translateY(0);
                transition: opacity 230ms ease-out 380ms, transform 230ms ease-out 380ms;
            }
        }
    }
}

.content {
    bottom: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.1);
    left: 0;
    box-shadow: none;
    transform: translateY(100%);
    visibility: hidden;
    padding: 1.5vh 1vw;
    min-height: vw(140);
    transition: all 230ms ease;
    border-bottom: 3px solid #f7be2f;

    &__title {
        font-size: vw(25);
        font-weight: bold;
        margin-bottom: 1vh;
        opacity: 0;
        transform: translateY(-40%);
    }

    &__text {
        font-weight: 300;
        font-size: vw(16);
        opacity: 0;
        transform: translateY(-40%);
    }
}
</style>