<template>
  <main class="main testimonials">
    <div class="main__inner">
      <h2 class="small-title mobile-only">לקוחות ממליצים</h2>
      <div class="items" ref="testimonialsElement">
        <div v-if="loading" class="loader"></div>
        <testimonial-item v-for="item in itemsToDisplay" :key="item.id" :type="item.type" :text="item.text"
          :video="item.video" :preview="item.preview" :image="item.image" :textLeft="item.text_left" />
      </div>
      <button class="load-more" :disabled="loading" @click="loadMore">
        להמלצות נוספות
      </button>
    </div>
  </main>
</template>
<script>
// Core
import { computed } from "@vue/runtime-core";
import { toRef, ref } from "@vue/reactivity";
import { useStore } from "vuex";

// Components
import TestimonialItem from "@/components/Testimonial.vue";

import { getOffset } from '@/inc/helpers'

export default {
  name: "ViewTestimonials",
  components: {
    TestimonialItem,
  },
  setup() {
    const store = useStore();
    const isMobile = toRef(store.getters, "isMobile");
    const content = toRef(store.getters.content, "testimonials");
    const items = toRef(content.value, "items");
    items.value.forEach((el, i) => el.id === i);
    const page = ref(0);
    const start = ref(0);
    const end = ref(items.value.length > 12 ? 11 : items.value.length);

    const itemsToDisplay = computed(() =>
      items.value.slice(start.value, end.value + 1)
    );

    const loading = ref(false);
    const testimonialsElement = ref(null)

    const loadMore = () => {
      loading.value = true;
      const total = items.value.length;

      var top = getOffset(testimonialsElement.value).top - 100;
      setTimeout(() => {
        if (items.value[end.value + 1]) {
          start.value = end.value + 1;
          if (end.value + 12 < total) {
            end.value += 12;
          } else {
            end.value = total - 1;
          }
          page.value++;
        } else {
          page.value = 0;
          start.value = 0;
          end.value = 11;
        }
        loading.value = false;
        window.scrollTo({
          top,
          behavior: 'smooth'
        })
      }, (Math.random() * (1.9 - 0.7) + 0.6) * 1000);
    };

    return { isMobile, content, itemsToDisplay, loading, loadMore, testimonialsElement };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.main {
  &__inner {
    flex-wrap: wrap;
    align-content: space-evenly;
    text-align: center;

    @include break($small) {
      text-align: right;
      justify-content: flex-start;

      .small-title {
        padding-bottom: 0px;
        order: -2;
        flex-basis: 100%;
      }
    }
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: vw(584, 1920);
  width: 100%;
  justify-content: flex-start;

  @include break($large ) {
    height: vw(424);
  }

  @include break($small ) {
    height: auto;
  }
}

.load-more {
  border-bottom: solid 2px #fbc02d;
  padding: vw(5);
  font-size: vw(24);


  @include break($small ) {
    order: -1;
    margin-bottom: 24px;
    font-size: 24px;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;

  }
}
</style>