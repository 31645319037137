<template>
  <v-chart class="chart" :option="option" />
  <div v-if="!props.isLeasing" class="box__btns">
    <a :href="ForecastPDF" class="btn btn--primary" target="_blank" @click="() => dataLayerYearly()">תחזית ההכנסות
      שלי</a>
  </div>

</template>
<script>
// Core
import { ref, defineComponent, toRefs } from "vue";
import { useStore } from "vuex";

// External
import { use, graphic } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { createDataLayerEvent } from '@/inc/helpers'

use([CanvasRenderer, LineChart, TooltipComponent, GridComponent]);

//const arr = [400, 0, 0, 500, 1000, 1500]



export default defineComponent({
  name: "IncomeChart",
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "",
  },
  props: {
    isLeasing: Boolean,
  },
  setup(props) {
    const store = useStore();
    const { DataForCustomer } = toRefs(store.getters.userData);
    const { ForecastPDF } =
      store.getters.userData.ProposalOutput;

    const xAxisData = DataForCustomer.value.map((el) => el.Year)
    const yAxisData = props.isLeasing ?
      ((currentLeasing) => {
        const result = []
        for (let i = 0; i < currentLeasing.Delay; i++) result.push(currentLeasing.PrePayment)
        for (let i = 0; i < (xAxisData.length - currentLeasing.Delay); i++) result.push(currentLeasing.PrePayment + currentLeasing.Yearly * i)
        return result
      })(store.state.currentLeasing)
      : DataForCustomer.value.map((el) => el.CumulativeFlow);

    const option = ref({
      grid: {
        // width: "100%",
        // height: "100%",
        top: "40",
        right: "15",
        bottom: "54",
        left: "70",
        backgroundColor: '#ddd',
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          console.log(params, 'params', params.value, params.data)
          return '₪' + params.value.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        renderMode: 'html',
        className: 'chart_tooltip',
        position: 'top',
        borderColor: 'rgba(0, 53, 138, .3)',
        padding: [4, 14, 0, 14]
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xAxisData,
        name: 'שנים',
        nameLocation: 'center',
        nameGap: 30,
        axisLabel: {
          color: '#00358a',
          formatter: function (value) {
            return value < 0 ? + '-' + (value * - 1) + '-' : value
          }
        },
        nameTextStyle: {
          color: '#00358a',
          fontSize: 14,
          fontFamily: "Assistant",
          fontWeight: 500,
        },
      },
      yAxis: {
        type: 'value',
        name: 'הכנסות',
        //nameGap: 30,
        nameTextStyle: {
          color: '#00358a',
          align: 'right',
          verticalAlign: 'bottom',
          fontSize: 14,
          fontFamily: "Assistant",
          fontWeight: 500,
          padding: [0, 7, 0, 0]
        },
        position: 'left',
        axisLabel: {
          color: '#00358a',
          formatter: function (value) {
            return value < 0 ? (value * - 1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '-' : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
        },
      },
      series: [
        {
          data: yAxisData,
          type: "line",
          //areaStyle: {},
          areaStyle: {
            opacity: 0.8,
            color: new graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 0,
                color: "rgba(251, 192, 45, .8)",
              },
              {
                offset: 1,
                color: "rgba(251, 192, 45, .2)",
              },
            ]),
          },
        },
      ],
    });

    const dataLayerYearly = () => {
      createDataLayerEvent({
        'event': 'price_quote',
        'pricing_model': 'Purchase',
        'buttons': 'Yearly Output Distribution'
      })
    }


    return { option, props, ForecastPDF, dataLayerYearly };
  },
});
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.box__btns {
  height: 12%;
  display: flex;
  align-items: flex-end;

  .btn {
    padding: 1vh 3vw;
  }
}
</style>