<template>
  <div class="fact shadow-light" :class="{ flex: flex }">
    <img :src="imgUrl" :alt="alt" />
    <div class="fact__content">
      <strong>
        <running-number :number="number" />
        {{ textAfter }}
      </strong>
      <p>{{ text }}</p>
    </div>
  </div>
</template>
<script setup>
// Core
import { defineProps, toRefs } from "vue";

// Components
import runningNumber from "./runningNumber.vue";

const props = defineProps({
  imgUrl: String,
  alt: {
    type: String,
    default: "",
  },
  number: Number,
  text: String,
  textAfter: String,
  flex: Boolean,
})

const { imgUrl, number, textAfter, text } = toRefs(props)
</script>
<style scoped lang="scss">
@import "@/scss/setup";

.fact {
  flex-grow: 1;
  text-align: center;
  height: vw(200, 1920);

  @include break($large) {
    height: vw(158);
  }

  @include break($small) {
    height: 100px;
  }

  &.flex {
    display: flex;
    justify-content: center;
    align-items: center;

    @include break($small) {
      padding: 8px;
    }
  }

  img {
    display: block;
    width: auto;
    margin-left: vw(25, 1920);

    @include break($large) {
      margin-left: vw(20);
    }

    @include break($small) {
      max-height: 90%;
      //width: 37%;
      margin-left: 7%;
    }
  }

  strong {
    font-size: vw(46, 1920);

    @include break($large) {
      font-size: vw(40);
    }

    @include break($small) {
      font-size: 27px;
    }
  }

  p {
    font-size: vw(26, 1920);
    font-weight: 300;

    @include break($large) {
      font-size: vw(21);
    }

    @include break($small) {
      font-size: 18px;
    }
  }
}
</style>