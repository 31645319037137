import { shallowRef } from 'vue';
import { v4 as uid } from 'uuid';

import ViewIntro from "../views/Intro.vue";
import ViewPlan from "../views/Plan.vue";
import ViewModel from '../views/Model.vue';
import ViewAbout from '../views/About.vue';
import ViewPro from '../views/Pro.vue';
import ViewDepartments from '../views/Departments.vue';
import ViewReferral from '../views/Referral.vue'
import ViewTestimonials from '../views/Testimonials.vue'

export default [

  {
    step: 0,
    text: 'עמוד הבית',
    active: true,
    component: shallowRef(ViewIntro),
    id: uid(),
    gtm_url: '/',
    gtm_title: 'הצעת מחיר - עמוד הבית'
  },
  {
    step: 1,
    text: 'הדמיה',
    active: false,
    component: shallowRef(ViewPlan),
    id: uid(),
    gtm_url: '/simulation',
    gtm_title: 'הצעת מחיר - הדמיה'
  },
  {
    step: 2,
    text: 'הצעת מחיר',
    active: false,
    component: shallowRef(ViewModel),
    id: uid(),
    gtm_url: '/pricing-quote',
    gtm_title: 'הצעת מחיר - הסכם'
  },
  {
    step: 3,
    text: 'מי אנחנו',
    active: false,
    component: shallowRef(ViewAbout),
    id: uid(),
    gtm_url: '/about-us',
    gtm_title: 'הצעת מחיר - מי אנחנו'
  },
  {
    step: 4,
    text: 'בטיחות ובילדרים',
    active: false,
    component: shallowRef(ViewPro),
    id: uid(),
    gtm_url: '/safety-and-builders',
    gtm_title: 'משתפים ומרוויחים - בטיחות ובילדרים'
  },
  {
    step: 5,
    text: 'המחלקות שלנו',
    active: false,
    component: shallowRef(ViewDepartments),
    id: uid(),
    gtm_url: '/our-departments',
    gtm_title: 'משתפים ומרוויחים - המחלקות שלנו'
  },
  {
    step: 6,
    text: 'משתפים ומרוויחים',
    active: false,
    component: shallowRef(ViewReferral),
    id: uid(),
    gtm_url: '/share-community',
    gtm_title: 'הצעת מחיר - משתפים ומרוויחים'
  },
  {
    step: 7,
    text: 'לקוחות ממליצים',
    active: false,
    component: shallowRef(ViewTestimonials),
    id: uid(),
    gtm_url: '/testimonials',
    gtm_title: 'משתפים ומרוויחים - לקוחות ממליצים'
  },
]