<template>
  <div class="box">
    <div class="box__data" :class="IsGroup ? 'isGroup' : ''">
      <p class="group" v-if="IsGroup" v-html="GroupBuyingDescription"></p>
      <data-card v-for="(card, i) in cards" :key="i" :title="card.title" :number="card.number"
        :afterNumber="card.afterNumber" :beforeNumber="card.beforeNumber" :tooltip="card.tooltip" :icon="card.icon" />
    </div>
    <div class="box__bottom">
      <div class="box__text">למידע נוסף:</div>
      <div class="box__btns">
        <button class="btn btn--primary" @click="() => openModal('warranty')">
          אחריות
        </button>
        <button class="btn" :class="[isMobile ? 'btn--empty' : 'btn--secondary']"
          @click="() => openModal('maintenance')">
          תחזוקה
        </button>
        <a v-if="!isMobile" :href="PurchaseAgreementPDF" class="btn btn--empty" target="_blank"
          @click="() => dataLayerAgreement()">הסכם</a>
      </div>
    </div>
  </div>
</template>
<script>
// Core
import { useStore } from "vuex";
import { toRef } from "vue";
import { createDataLayerEvent } from '@/inc/helpers'
// Components
import DataCard from "@/components/DataCard.vue";

export default {
  name: "BuyingContent",
  components: { DataCard },
  setup() {
    const store = useStore();
    const { FirstYearIncome, FinalSystemPrice, ROI, IRR, TotalIncome, PurchaseAgreementPDF, IsGroup, GroupName, DiscountFromGroup } =
      store.getters.userData.ProposalOutput;
    const content = store.getters.content;

    const cards = [
      {
        title: "עלות מערכת",
        number: Math.round(FinalSystemPrice),
        afterNumber: "₪",
        beforeNumber: null,
      },
      {
        title: "הכנסות שנה ראשונה",
        number: Math.round(FirstYearIncome),
        afterNumber: "₪",
        beforeNumber: null,
      },
      {
        title: "סה״כ הכנסות",
        number: Math.round(TotalIncome),
        afterNumber: "₪",
        beforeNumber: null,
        icon: require("@/assets/icon_coin.svg"),
        tooltip: content.price_offer.buying_tooltip,
      },
      {
        title: "החזר השקעה",
        number: ROI,
        afterNumber: null,
        beforeNumber: " שנים",
      },
      {
        title: "תשואה",
        number: (IRR * 100).toFixed(0),
        afterNumber: null,
        icon: require("@/assets/up.png"),
        beforeNumber: "%",
      },
    ];

    const openModal = (name) => {
      createDataLayerEvent({
        'event': 'price_quote',
        'pricing_model': 'Purchase',
        'buttons': name,
        'grant_amount': Math.round(TotalIncome),
        'agreement_id': PurchaseAgreementPDF
      })
      store.commit("setModal", name);
    }

    const dataLayerAgreement = () => {
      createDataLayerEvent({
        'event': 'price_quote',
        'pricing_model': 'Purchase',
        'buttons': 'Agreement',
        'grant_amount': Math.round(TotalIncome),
        'agreement_id': PurchaseAgreementPDF
      })
    }

    let GroupBuyingDescription = content.price_offer.group_buying_descripion;
    if (IsGroup) {
      GroupBuyingDescription = GroupBuyingDescription.replaceAll('\n', '<br />').replace('{discount}', Math.round(DiscountFromGroup).toLocaleString()).replace('{groupName}', GroupName);
    }

    const isMobile = toRef(store.getters, "isMobile");
    return { cards, PurchaseAgreementPDF, openModal, isMobile, dataLayerAgreement, IsGroup, GroupBuyingDescription };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__data {
    display: flex;
    flex-wrap: wrap;
    margin: 0 vw(-4);
    text-align: center;
    margin-top: 15%;

    @include break($small) {
      margin-top: 0;
      justify-content: space-between;
    }

    >.card {
      &:nth-of-type() {
        @include break($small) {
          //order: -1;
        }
      }

      &:nth-of-type(1),
      &:nth-of-type(2) {
        flex: 1 0 calc(50% - vw(8));

        @include break($small) {
          flex: 1 0 100%;
        }
      }

      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        flex-grow: 1;

        @include break($small) {
          flex: 0 0 48%;
        }
      }

      &:nth-of-type(3) {
        @include break($small) {
          flex: 0 0 100%;
        }
      }

      &:nth-of-type(5) {
        @include break($small) {
          text-align: right;
        }

        .card__number {
          margin: 0;
        }
      }
    }

    &.isGroup {
      margin-top: 2%;

      >p.group {
        width: 100%;
        font-size: vw(16);
        font-weight: bold;
        margin-bottom: 2%;

        @include break($small) {
          font-size: 16px;
        }
      }
    }
  }

  &__text {
    font-size: vw(16);
    font-weight: 300;
    margin-bottom: vw(20);

    @include break($small) {
      display: none;
    }
  }

  .btn:not(:last-child) {
    margin-left: vw(10);
  }

  &__btns {
    @include break($small) {
      display: flex;
      gap: 8px;
    }

    .btn {
      @include break($small) {
        flex-grow: 1;
        margin: 24px 0 0 !important;
      }
    }
  }
}
</style>