<template>
  <div class="card" :style="{ flexGrow: grow }">
    <div class="card__content">
      <div class="card__title">{{ title }}</div>
      <div class="card__number-wrapper">
        <div class="card__number">
          <span v-if="afterNumber" class="afterNumber">{{ afterNumber }}</span>
          <running-number v-if="typeof number === 'number'" :number="number" />
          <span v-else>{{ number }}</span>
          <span v-if="beforeNumber" class="beforeNumber">{{ beforeNumber }}</span>
        </div>
      </div>
    </div>
    <div class="card__end">
      <tool-tip v-if="tooltip" :content="tooltip" />
      <img iv-if="icon" :src="icon" alt="" />
    </div>
  </div>
</template>
<script>
import { toRef } from '@vue/reactivity';
//import { toRefs } from "vue";
// Components
import RunningNumber from "./runningNumber.vue";
import ToolTip from "./ToolTip.vue";

export default {
  name: "DataCard",
  components: {
    RunningNumber,
    ToolTip,
  },
  props: {
    title: String,
    number: [String, Number],
    afterNumber: String,
    beforeNumber: String,
    tooltip: String,
    icon: String,
    grow: String,
  },
  setup(props) {
    const reactiveNumber = toRef(props, 'number')
    return { ...props, reactiveNumber };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.card {
  $c: &;
  box-shadow: 0 8px 22px 4px rgba(0, 53, 138, 0.15);
  margin: vw(4, 1920);
  padding: vw(9, 1920) vw(6, 1920);
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;

  @include break($large) {
    margin: vw(4);
    padding: vw(9) vw(7);
  }

  @include break($small) {
    padding: 10px;
    margin-bottom: 8px;
  }

  &__content {
    flex-grow: 1;
  }

  &__title {
    font-size: vw(20, 1920);
    font-weight: 300;

    @include break($large) {
      font-size: vw(16);
    }

    @include break($small) {
      font-size: 16px;

      #{$c}:nth-child(5) & {
        padding-right: 3px;
      }
    }
  }

  .afterNumber,
  .beforeNumber {
    font-size: vw(20, 1920);
    margin-right: 2px;

    @include break($large) {
      font-size: vw(16);
    }

    @include break($small) {
      font-size: 16px;
    }
  }

  &__number {
    font-size: vw(34, 1920);
    font-weight: bold;
    direction: rtl;
    margin: 0 auto;

    [isleasing="false"] #{$c}:nth-child(5) & {
      @include break($small) {
        margin: 0;
      }
    }

    @include break($large) {
      font-size: vw(26);
    }

    @include break($small) {
      font-size: 27px;
    }
  }

  &__number-wrapper {
    display: flex;

    text-align: center;
  }

  &__end {
    display: flex;
    align-items: center;

    @include break($small ) {
      position: absolute;
      left: 8px;

    }

  }

  img {
    max-width: 60px;
  }
}
</style>