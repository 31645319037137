<template>
  <div class="toggle-box">
    <div class="top">
      <button v-for="item in d" :key="item.id" @click="() => setActiveComponent(item)"
        :class="{ active: activeId === item.id }">
        <span>{{ item.title }}</span>
      </button>
    </div>
    <div class="pannel">
      <component :is="activeComponent" :isLeasing="props.isLeasing"></component>
    </div>
  </div>
</template>
<script>
import { toRefs, ref } from "vue";
import { createDataLayerEvent } from '@/inc/helpers'

export default {
  name: "ToggleChildren",
  props: {
    data: Object,
    isLeasing: Boolean,
  },
  components: {},
  setup(props) {
    const { data: d } = toRefs(props);

    console.log(d)

    const activeComponent = ref(null);
    const activeId = ref(null);
    activeComponent.value = d.value[0].component.value;
    activeId.value = d.value[0].id;

    function setActiveComponent(item) {

      let title = '';
      switch(item.title) {
        case 'גרף הכנסות':
          title = 'Revenue Graph'
          break;
        case 'ליסינג':
          title = 'Leasing'
          break;
        case 'רכישה':
          title = 'Purchase'
          break;
      }
      createDataLayerEvent({
        'event': 'price_quote',
        'pricing_model': props.isLeasing ? 'Leasing' : 'Purchase',
        'buttons': title
      })

      d.value.forEach((el) => {
        if (el.id === item.id) {
          activeComponent.value = el.component.value;
          activeId.value = el.id;
        }
      });
    }

    return { d, activeComponent, setActiveComponent, activeId, props };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.toggle-box {
  box-shadow: 0px 0px 15px rgba(129, 74, 74, 0.25);
  display: flex;
  flex-direction: column;
  height: vw(592, 1920);
  max-height: 100%;

  @include break($large) {
    height: vw(500);
    max-height: calc(100vh - 120px);

  }

  @include break($small) {
    height: auto;
    box-shadow: none;
  }
}

.top {
  display: flex;

  @include break($small) {
    display: none;
  }

  button {
    flex: 1;
    font-size: vw(20, 1920);
    font-weight: 600;
    padding: vw(12, 1920);
    color: rgba(60, 62, 64, 0.7);

    @include break($large) {
      padding: vw(12);
      font-size: vw(15);
    }

    &.active {
      background-color: #fff;
      color: $primary;
      font-weight: bold;

      span {
        border-bottom: 2px solid $secondary;
      }
    }

    &:not(.active) {
      box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.05);
    }
  }
}

.pannel {
  height: 100%;
  max-height: 500px;
  padding: 0 vw(25, 1920) vw(25, 1920);
  background-color: #fff;

  @include break($large) {
    padding: 0 vw(25) 16px vw(25);
  }

  @include break($small) {
    padding: 0;
  }
}
</style>