<template>
  <main class="main intro">
    <div class="main__inner">
      <div class="layout">
        <h1 class="main-title">
          {{ isGroup ? groupName : 'משפחת ' + family }}
          <br />
          עוברת לאנרגיה סולארית
        </h1>
        <h2 class="subtitle" v-if="isGroup">משפחת {{ family }}</h2>
        <h2 class="subtitle">{{ address }}</h2>
        <div class="contact">
          <p class="contact-text">{{ expert.Name }}, {{ expert.Role }}</p>
          <ul class="icons">
            <li class="icon icon--wa">
              <a :href="`https://wa.me/${expert.Phone}`" target="_blank" class="icon__btn icon__btn--wa"
                @click="() => sendDataLayerEvent('Whatsapp')">
                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29 24.39c-.05.27-.09.53-.15.8-.096.4-.33.754-.66 1a3.35 3.35 0 0 1-1.59.81 2.659 2.659 0 0 1-.93.1c-.36-.07-.73-.14-1.09-.24a17.432 17.432 0 0 1-2.35-.86 10.468 10.468 0 0 1-2.67-1.9 16.41 16.41 0 0 1-2.72-3.48 4.592 4.592 0 0 1-.66-1.9A3.41 3.41 0 0 1 17 16c.12-.143.25-.277.39-.4a1.13 1.13 0 0 1 .82-.25h.62a.51.51 0 0 1 .46.29c.13.26.24.52.35.79l.78 1.83a.65.65 0 0 1 0 .51 4 4 0 0 1-.8 1.07c-.24.27-.26.37-.07.68a8.31 8.31 0 0 0 3.64 3.35l.64.29a.44.44 0 0 0 .59-.12l1-1.21a.44.44 0 0 1 .6-.19c.4.16.8.35 1.2.53l1.39.68c.29.15.39.15.39.54z"
                    fill="#fff" />
                  <path
                    d="m9.51 34.58.13-.37c.71-2.1 1.42-4.21 2.14-6.31a.321.321 0 0 0 0-.27 12.529 12.529 0 0 1-1.58-4.26 12.669 12.669 0 0 1-.2-2.68 12.42 12.42 0 0 1 2.93-7.57 12.61 12.61 0 0 1 7.21-4.35A10.19 10.19 0 0 1 22 8.53h1.33a12.76 12.76 0 0 1 9 4.23 12.57 12.57 0 0 1 3 5.76c.154.674.244 1.36.27 2.05v1.57a12.233 12.233 0 0 1-1 4.2 12.81 12.81 0 0 1-9.6 7.38c-.878.153-1.77.217-2.66.19a12.74 12.74 0 0 1-5.62-1.5.39.39 0 0 0-.3 0L9.7 34.53l-.19.05zm3.28-3.2h.15l3.81-1.22a.3.3 0 0 1 .26 0c.25.15.51.31.77.45a10.68 10.68 0 0 0 5.63 1.22 9.704 9.704 0 0 0 1.85-.26 10.7 10.7 0 0 0 6.23-4.13 10.331 10.331 0 0 0 2-5.63 9.926 9.926 0 0 0-.19-2.66A10.38 10.38 0 0 0 29.55 13a10.48 10.48 0 0 0-7.45-2.36 9.218 9.218 0 0 0-1.65.23 10.61 10.61 0 0 0-5.91 3.6 10.55 10.55 0 0 0-2.31 8.67 10.61 10.61 0 0 0 1.82 4.27.25.25 0 0 1 0 .22L12.91 31c-.04.13-.07.24-.12.38z"
                    fill="#fff" />
                </svg>
              </a>
            </li>

            <li class="icon icon--secondary" :class="{ active: btns.phone }"
              :style="{ width: btns.phone && activeBtnWidth ? activeBtnWidth : '' }">
              <button class="icon__btn" @click="(e) => setBtn('Phone', e)">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.141 35.56c2.443.772 3.758.475 5.136-.475 11.211-7.537 18.79-15.728 24.364-26.292.689-1.306.313-2.493-.501-3.977C34.012 2.798 31.32.662 31.32.662c-1.755-1.365-3.947-.475-5.074 1.365-1.378 2.077-2.443 4.688-2.944 5.875-.376.95-.062 2.018.752 2.671l1.315 1.069c.94.771 1.253 2.136.501 3.086-3.695 4.985-6.639 8.428-11.837 11.87-1.002.653-2.38.712-3.32-.06l-1.19-.949a2.677 2.677 0 0 0-2.88-.297c-1.003.475-2.945 2.196-3.759 2.968-1.315 1.187-4.07 3.383-2.317 4.807 0 0 1.002.831 3.695 1.9"
                    fill="#fff" />
                </svg>
              </button>
              <a :href="`tel:${expert.Phone}`" class="icon__link icon__link--phone" ref="phoneLink"
                @click="() => sendDataLayerEvent('Phone')">{{
                    phoneNumberFormatted
                }}</a>
            </li>
            <li class="icon icon--primary" :class="{ active: btns.email }"
              :style="{ width: btns.email && activeBtnWidth ? activeBtnWidth : '' }">
              <button class="icon__btn" @click="(e) => setBtn('Email', e)">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.864 27.245c-.4 0-.8 0-1.143-.108-4.973-.648-8.23-5.621-7.373-11.08.4-2.595 1.657-4.92 3.6-6.541 2.001-1.676 4.402-2.433 6.86-2.108 4.916.702 8.23 5.675 7.373 11.135-.4 2.594-1.657 4.919-3.6 6.54-1.715 1.406-3.716 2.162-5.716 2.162zm.744-16.973c-1.258 0-2.573.487-3.659 1.406-1.371 1.189-2.286 2.865-2.572 4.756-.629 3.838 1.543 7.352 4.744 7.784 1.487.216 3.03-.27 4.345-1.351 1.371-1.19 2.286-2.865 2.572-4.757.628-3.838-1.544-7.351-4.744-7.784-.23 0-.458-.054-.686-.054z"
                    fill="#fff" />
                  <path
                    d="M19.034 36C8.517 36 0 27.892 0 18S8.517 0 19.034 0c10.575 0 15.262 7.135 16.405 11.297 1.086 4.216 1.029 13.081-4.173 14.379-2.4.594-4.001-.162-4.916-.865-3.43-2.757-3.315-10.108-2.686-15.784.114-.81.857-1.351 1.715-1.297.857.108 1.429.81 1.371 1.621-.914 8 .23 12.108 1.601 13.244.343.27.915.594 2.115.27 2.515-.649 3.03-7.027 2-10.865-.285-.919-2.743-9.081-13.432-9.081C10.232 2.919 3.087 9.676 3.087 18s7.145 15.081 15.947 15.081c.857 0 1.543.649 1.543 1.46 0 .81-.686 1.459-1.543 1.459z"
                    fill="#fff" />
                </svg>
              </button>
              <a :href="`mailto:${expert.Email}`" class="icon__link icon__link--email" ref="emailLink"
                @click="() => sendDataLayerEvent('Email')">{{
                    expert.Email
                }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="lottie-wrapper">
      <lottie-animation class="anim1" v-if="isMobile" ref="animMob" :animationData="mobileAnimation" />
      <lottie-animation v-else class="anim2" ref="animDesk" :animationData="desktopAnimation" />
    </div>
    <div v-if="!isMobile" class="credit">
      created by:
      <a href="https://www.layer.co.il/" target="_blank">Layer.co.il</a>
    </div>
  </main>
</template>

<script>
// Core
import {
  ref,
  toRef,
  watch,
  nextTick,
} from "vue";
import { useStore } from "vuex";

import { formatPhone, createDataLayerEvent } from '@/inc/helpers'


export default {
  name: "ViewIntro",
  props: {},
  components: {},
  setup() {
    const animMob = ref(null)
    const animDesk = ref(null)
    const store = useStore();
    const isMobile = toRef(store.getters, "isMobile");
    const startAnimation = ref(true);
    const { SolarExpertDetails: expert, ProposalOutput: { FamilyNameHebrew: family, AddressHebrew: address, IsGroup: isGroup, GroupName: groupName } } = store.getters.userData
    const phoneNumberFormatted = formatPhone(expert.Phone)
    //const { SolarExpertDetails: expert } = store.getters.userData
    const mobileAnimation = require("@/assets/house_mobile.json");
    const desktopAnimation = require("@/assets/lottie/desktop_house.json");
    const activeBtnWidth = ref(0)
    const btns = ref({ phone: false, email: false });

    function setBtn(btn, e = null) {
      if (btn === "Phone") {
        btns.value.phone = !btns.value.phone;
        btns.value.email = false;
      } else {
        btns.value.phone = false;
        btns.value.email = !btns.value.email;
      }
      if (e) {
        nextTick(() => {
          const width = window.document.querySelector('.icon.active .icon__link')?.scrollWidth
          if (width && !isMobile.value)
            activeBtnWidth.value = width + 150 + 'px'
          if (width && isMobile.value)
            activeBtnWidth.value = width + 65 + 'px'
        })
      }
    }

    function sendDataLayerEvent(btn) {
      createDataLayerEvent({
        'event': 'contact_us',
        'contact_medium': btn,
        'button_location': 'Main Page'
      });
    }
    watch(
      () => store.state.currentStep,
      () => {
        startAnimation.value =
          store.state.steps.find((step) => step.active).component?.name ===
          "ViewIntro";

        const anim = animMob.value || animDesk.value;
        // if (anim) startAnimation.value ? anim.play() : anim.stop();
        if (anim) anim.play();
      }
    );

    return {
      isGroup,
      groupName,
      family,
      address,
      btns,
      setBtn,
      expert,
      isMobile,
      mobileAnimation,
      desktopAnimation,
      startAnimation,
      activeBtnWidth,
      animMob,
      animDesk,
      phoneNumberFormatted,
      sendDataLayerEvent
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/setup";

.intro {
  background-color: $light-bg;

  @include break($small) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }

  .main__inner {
    z-index: 2;
    position: relative;
  }
}

.subtitle {
  font-size: vw(38, 1920);
  font-weight: bold;

  &:first-of-type {
    margin-top: vw(44, 1920);
  }

  @include break($large) {
    font-size: vw(34);

    &:first-of-type {
      margin-top: vw(44);
    }
  }

  @include break($small) {
    font-size: 6vw;
    font-weight: normal;
  }
}

.contact-text {
  font-size: vw(34, 1920);
  margin-top: vw(50, 1920);
  font-weight: 300;
  margin-bottom: vw(25, 1920);

  @include break($large) {
    font-size: vw(26);
    margin-top: vw(60);
    margin-bottom: vw(25);
  }

  @include break($small) {
    font-size: 21px;
    font-size: 5vw;
    font-weight: 300;
    margin-top: 40px;
    margin-bottom: 32px;
  }
}

.layout {
  width: 100%;
  height: 100%;
}

.icons {
  display: flex;
  align-items: center;


  .icon {
    overflow: hidden;
    background-color: $secondary;
    height: vw(90, 1920);
    width: vw(90, 1920);
    overflow: hidden;
    border-radius: vw(45, 1920);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    transition: width 230ms ease-out, background-color 180ms ease-out;
    margin-left: vw(12);


    @include break($large) {
      height: vw(90);
      width: vw(90);
      border-radius: vw(45);
    }

    @include break($small) {
      height: 20vw;
      width: 20vw;
      height: 45px;
      width: 45px;
      border-radius: 100px;
      margin-left: 2vw;
    }

    &.active {
      /*  width: vw(350, 1920);

      @include break($large) {
        width: vw(350);
      }
      @include break($small) {
        width: 180px;
      }
      */
    }

    &--wa {
      background-color: #2fbe39;

      &:hover {
        background-color: darken(#2fbe39, 4%);
      }
    }

    &--primary {
      background-color: $primary;

      &:hover {
        background-color: darken($primary, 4%);
      }
    }

    &--secondary {
      background-color: $secondary;

      &:hover {
        background-color: darken($secondary, 4%);
      }
    }

    &__link {
      white-space: nowrap;
      margin: 0 vw(20, 1920);
      font-size: vw(30, 1920);
      color: #fff;
      direction: ltr;

      @include break($large) {
        margin: 0 vw(20);
        font-size: vw(30);
      }

      @include break($small) {
        font-size: 18px;
        margin: 0;
      }

      &--email {
        @include break($small) {
          font-size: 15px;
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 vw(90, 1920);

      @include break($large) {
        flex: 0 0 vw(90);
      }

      @include break($small) {
        flex: 0 0 45px;
      }

      &--wa {
        svg {
          width: 90px;
          height: 90px;
          max-width: 100%;
        }
      }
    }
  }

  svg {
    height: 58px;
    width: auto;
    max-width: 70%;
  }
}

.lottie-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  direction: ltr;
  z-index: 0;
  max-height: 115%;

  @include break($small) {
    position: static;
  }

  >div {
    width: 100vw;

    @include break($large) {
      height: auto;
    }
  }
}

.credit {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  z-index: 20;
  font-size: 15px;
}
</style>
<style lang="scss">
.lottie-wrapper {
  svg {
    display: block;
  }
}
</style>
