<template>
  <div class="main departments">
    <div class="main__inner">
      <h2 class="small-title mobile-only">המחלקות שלנו</h2>
      <div class="videos">
        <template v-if="isMobile">
          <swiper :modules="modules" pagination :loop="true" :slides-per-view="1.4" :space-between="20"
            :centeredSlides="true" class="departments-slider">
            <swiper-slide v-for="item in items" :key="item.id">
              <department-mobile :item="item" />
            </swiper-slide>
          </swiper>
        </template>
        <template v-else>
          <department-desktop v-for="item in items" :key="item.id" :item="item" />
        </template>

      </div>
    </div>
  </div>
</template>
<script>
// Core
import { ref, toRef } from "vue";
import { useStore } from "vuex";

//3rd
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";
import "swiper/css/pagination";


// Components
import DepartmentDesktop from '@/components/DepartmentDesktop.vue'
import DepartmentMobile from '@/components/DepartmentMobile.vue'

export default {
  name: "ViewDepartments",
  components: { DepartmentDesktop, Swiper, SwiperSlide, DepartmentMobile },
  setup() {
    const store = useStore();
    const items = ref(store.getters.content.departments.items);
    const isMobile = toRef(store.getters, 'isMobile')
    items.value = items.value.map((item, index) => ({
      id: index + 1,
      title: item.title,
      text: item.text,
      img: item.image.url,
      active: false,
      prevUrl: item.preview,
      video: item.video,
    }));
    return { items, isMobile, modules: [Pagination], };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.main {
  @include break($small) {
    background-image: url("@/assets/backgrounds/mobile-bg4.svg");
    background-repeat: no-repeat;
    background-position: left 0 bottom 70vw;
    background-size: 30%;
  }

  &__inner {
    align-items: flex-start;
    height: 100%;

    @include break($small ) {
      display: block;
      margin-top: -30px;
      padding: 20px 0 38vw;
      background-image: url("@/assets/backgrounds/mobile-bg5.svg");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 85%;

      .small-title {
        padding: 20px;
      }
    }
  }
}

.videos {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 0 20px;
  margin-top: -80px;

  @include break($large ) {
    //margin-top: vw(30);
  }

  @include break($small ) {
    margin-top: 10vw;
    padding: 0;
  }
}

.departments {
  margin-bottom: 50px;
}

.departments-slider {
  padding-bottom: 48px;

  &:deep(.swiper-pagination-bullet) {
    height: 10px;
    width: 10px;
    background-color: $secondary;
    opacity: .8;
  }

  &:deep(.swiper-pagination-bullet-active) {
    background-color: $primary-light;
    opacity: 1;
  }
}
</style>