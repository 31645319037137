<template>
  <main class="main referral">
    <div class="main__inner">
      <div class="col animation">
        <lottie-animation ref="referralAnim" :animationData="isMobile ? mobileAnimation : desktopAnimation"
          :loop="true" />
      </div>
      <div class="col referral-content">
        <h3 class="small-title mobile-only">רפרל</h3>
        <h3 class="referral-title">{{ content.title }}</h3>
        <div class="running-text" v-html="content.content"></div>
        <a v-if="content.button" :href="content.button.url" class="btn btn--secondary"
          :target="content.button.target" @click="() => dataLayerButton()">{{ content.button.title }}</a>
      </div>
    </div>
  </main>
</template>
<script>
import { ref, toRef } from "@vue/reactivity";
import { useStore } from "vuex";
import { watch } from "@vue/runtime-core";
import { createDataLayerEvent } from '@/inc/helpers'
export default {
  name: "ViewReferral",
  setup() {
    const startAnimation = ref(false);
    const referralAnim = ref(null)

    const store = useStore();
    const isMobile = toRef(store.getters, "isMobile");
    const content = toRef(store.getters.content, "referral");
    const desktopAnimation = require("@/assets/lottie/desktop_refferal.json");
    const mobileAnimation = require("@/assets/lottie/mobile_refferal2.json");

    watch(
      () => store.state.currentStep,
      () => {
        startAnimation.value =
          store.state.steps.find((step) => step.active).component?.name === "ViewReferral";
        startAnimation.value ? referralAnim?.value?.play() : referralAnim?.value?.stop();

      }
    );

    const dataLayerButton = () =>  {
      createDataLayerEvent({
        'event': 'Registration CTA',
        'cta_location': 'Pricing Quote Community Page'
      })
    }

    return { desktopAnimation, mobileAnimation, isMobile, content, startAnimation, referralAnim, dataLayerButton };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.main {
  background-image: url("@/assets/referal-bg.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 160vmin;

  @include break($small) {
    margin-top: -30px;
    background-image: url("@/assets/backgrounds/mobile-bg2.svg");
    background-position: bottom 40vw left 0;
    background-size: 51%;
  }

  &__inner {
    @include break($small) {
      padding-top: 30px;
      padding-bottom: 80vw;
      background-image: url("@/assets/backgrounds/b.svg");
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 80%;
    }
  }
}

.animation {
  position: absolute;
  bottom: -7vmin;
  right: 23vmin;

  >div {
    width: 90vmin;
    height: 90vmin;
  }

  @include break($small ) {
    right: unset;
    right: 16vw;
    bottom: 27vw;

    >div {
      width: 88vmin;
      height: 88vmin;
    }
  }
}

.referral-title {
  font-size: vw(48);
  margin-bottom: vw(22);

  @include break($small ) {
    font-size: 27px;
    font-weight: 300;
    margin-top: 14px;
  }
}

.referral-content {
  margin-right: auto;
  padding-left: vw(50);
  height: 100%;
  width: vw(445);
  padding-top: vw(20);

  @include break($small ) {
    width: 100%;
    margin: 0;
  }

  .running-text {
    margin-bottom: vw(22);

    @include break($small ) {
      max-width: 66%;
      margin: 10px 0 40px;
    }

    & :deep(p:first-child) {
      @include break($small) {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;
      }

    }
  }
}
</style>