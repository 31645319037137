<template>
    <div class="item">
        <div class="imgaw-wrapper">
            <video-btn :url="item.video" :wrapper-play="true" />
            <img :src="item.img" alt="" class="image">
        </div>
        <div class="content">
            <h3 class="content__title">{{ item.title }}</h3>
            <p class="content__text">{{ item.text }}</p>
        </div>
    </div>
</template>
<script>
// Core
import { ref } from '@vue/reactivity'

// Components
import VideoBtn from '@/components/VideoBtn.vue'
export default {
    props: {
        item: Object
    },
    components: {
        VideoBtn
    },
    setup(props) {
        const active = ref(false)
        const video = ref(null)
        const setActive = () => {
            active.value = !active.value
            active.value ? video.value.play() : video.value.pause()
        }


        return { ...props, setActive, active, video }
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.item {
    box-shadow: 0 8px 22px 4px rgba(0, 53, 138, 0.15);
    //padding-bottom: 16px;
    background-color: #fff;
    z-index: 5;
}

.imgaw-wrapper {

    @include break($small) {
        height: 0;
        padding-bottom: 70%;
        position: relative;
    }
}

.image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top center;

    @include break($medium) {
        height: 100%;
        position: absolute;
    }
}

.content {
    padding: 14px;
    min-height: 180px;

    &__title {
        margin-bottom: 8px;
    }
}

.imgaw-wrapper {
    position: relative;
}
</style>