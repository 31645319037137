<template>
  <show-error v-if="apiError" :data="apiError" />
  <div class="app-contaienr" v-else-if="content && userData">
    <app-header :bright="brightHeader" />
    <Waypoint @change="(data) => wpHandler(data)" />
    <swiper @swiper="onSwiper" :navigation="navigation" :modules="modules" @afterInit="onAfterInit"
      @slideChange="onSlideChange" @navigationNext="onNavigationNext" @navigationPrev="onNavigationPrev"
      :enabled="!isMobile" class="main-slider" :simulateTouch="false" :initialSlide="initialSlide">
      <swiper-slide v-for="step in steps" :key="step.id" :data-step="step.step">
        <content-container>
          <component :is="step.component" :isActive="step.active"></component>
        </content-container>
      </swiper-slide>
      <lottie-animation class="swiper-button-prev" :loop="true" :animationData="swiperPrevAnimation"
        :autoPlay="false" />
      <lottie-animation class="swiper-button-next" :loop="true" :animationData="swiperNextAnimation" :autoPlay="true" />
    </swiper>


    <transition name="modal" mode="in-out">
      <div v-if="modal && modal.active" class="modal-wrapper">
        <modal-box>
          <component :is="modal.component" />
        </modal-box>
      </div>
    </transition>
  </div>
  <div v-else class="loader"></div>
</template>
<script>
// Core
import { computed, ref, toRef } from "vue";
import { useStore } from "vuex";

// 3rd
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, History } from "swiper";
import { Waypoint } from 'vue-waypoint'
import { createDataLayerEvent, getProposalIdFromUrl } from '@/inc/helpers'


// CSS
import "swiper/css";
import "swiper/css/navigation";

// Components
import AppHeader from "./components/Header.vue";
import ContentContainer from "./components/ContentContainer.vue";
import ModalBox from "./components/Modal.vue";
import ShowError from './components/ShowError.vue'

export default {
  name: "App",
  components: {
    AppHeader,
    ContentContainer,
    Swiper,
    SwiperSlide,
    ModalBox,
    Waypoint,
    ShowError
  },
  setup() {
    const store = useStore();
    const { steps } = store.state;
    const isMobile = toRef(store.getters, 'isMobile');
    const acticvStep = computed(() => steps.find((el) => el.active));

    const initialSlide = !isMobile.value && window.location.hash ? window.location.hash.substr(1) : 0

    /**
     * Global listeners
     */
    window.addEventListener("resize", () => {
      console.log('ismobile', isMobile.value)
      store.commit("setIsMobile");
    });
    /**
     * Main Slider
     */
    const onSwiper = (swiper) => {
      store.state.mainSlider = swiper;
    };
    const onAfterInit = (swiper) => {
      if (swiper.activeIndex != 0) return;
      console.log('onAfterInit', swiper.activeIndex);
      const currentStep = steps[swiper.activeIndex]
      createDataLayerEvent({
        'event': 'pageview',
        'page_url': currentStep.gtm_url,
        'page_title': currentStep.gtm_title,
        'proposal_id': getProposalIdFromUrl()
      })
    };
    const onSlideChange = (swiper) => {
      const currentStep = steps[swiper.activeIndex]
      createDataLayerEvent({
        'event': 'pageview',
        'page_url': currentStep.gtm_url,
        'page_title': currentStep.gtm_title,
        'proposal_id': getProposalIdFromUrl()
      })
      store.commit("setStep", swiper.activeIndex);
    };

    const onNavigationNext = () => {
      createDataLayerEvent({
        'event': 'navigate',
        'navigate_to': 'Next Page'
      })
    };

    const onNavigationPrev = () => {
      createDataLayerEvent({
        'event': 'navigate',
        'navigate_to': 'Previous Page'
      })
    };

    /**
     * User Data
     */
    store.dispatch("fetchUserData");
    const userData = computed(() => store.getters.userData);

    /**
     * Content
     */
    store.dispatch("fetchContent");
    const content = computed(() => store.getters.content);
    /**
     * Modals
     */
    const { modals } = store.getters;
    const modal = computed(() => store.state.activeModal);
    const brightHeader = ref(false)
    const wpHandler = (data) => {
      brightHeader.value = data.direction === 'UP'
      console.log('DATA', data)
    }

    /**
     * Errors 
     */
    const apiError = toRef(store.state, 'apiError')


    const swiperNextAnimation = require("@/assets/lottie/swipper_next.json");
    const swiperPrevAnimation = require("@/assets/lottie/swipper_prev.json");

    return {
      steps,
      acticvStep,
      onSwiper,
      onAfterInit,
      onSlideChange,
      onNavigationNext,
      onNavigationPrev,
      userData,
      modules: [Navigation, History],
      modals,
      modal,
      isMobile,
      content,
      wpHandler,
      brightHeader,
      apiError,
      initialSlide,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      swiperPrevAnimation,
      swiperNextAnimation
    };
  },
  created: function () {
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/global";

.app-container {
  @include break($small) {
    background-color: $light-bg;
  }
}

.main-slider {
  >.swiper-wrapper {
    @include break($small) {
      display: block;
      overflow: initial;
    }
  }
}

.modal-wrapper {
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;

  @include break($small) {}
}
</style>
<style lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: all 330ms linear;

  .modal__inner {
    transition: all 280ms ease-out;
  }
}

.modal-enter-from,
.modal-leave-to {
  background-color: rgba(0, 0, 0, 0);

  .modal__inner {
    transform: translateY(-20%);
    opacity: 0;
  }
}
</style>
