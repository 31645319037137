<template>
  <div :class="{ testimonial: 1, [`type-${type}`]: 1, ['text-left']: textLeft }">
    <img class="image" v-if="type === 'image_text'" :src="image.url" alt="" />
    <p v-if="type === 'image_text'" class="image-text text" v-html="text"></p>
    <!-- <p v-if="type === 'video_text'" class="video-text text">{{ text }}</p> -->
    <video class="preview" v-if="type.includes('video')" :src="preview.url" autoplay playsinline muted loop></video>
    <video-btn :url="video.url" :small="true" v-if="type.includes('video')" :wrapper-play="true" />
    <span class="hint" v-if="type === 'image_text'">&#10005;</span>
  </div>
</template>
<script>
import { toRef } from '@vue/reactivity';
// Components
import VideoBtn from "./VideoBtn.vue";
import { useStore } from 'vuex';
export default {
  name: "TestimonialItem",
  components: { VideoBtn },
  props: {
    type: String,
    video: [Object, Boolean],
    text: String,
    preview: [Object, Boolean],
    image: [Object, Boolean],
    textLeft: Boolean,
  },
  setup(props) {
    const store = useStore()
    const isMobile = toRef(store.getters, 'isMobile')
    return { ...props, isMobile };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.testimonial {
  flex: 1 0;
  min-width: 13%;
  max-width: 33%;
  margin: vw(6, 1920);
  height: vw(280, 1920);
  position: relative;
  overflow: hidden;

  @include break($large ) {
    margin: vw(6);
    height: vw(200);
  }

  @include break($small ) {
    min-width: initial;
    max-width: initial;
    min-height: 33vw;
    margin: 4px;
    height: 130px;
    flex: 1 1 calc(100% / 3 - 8px);

  }

  &.type-video_text {
    flex-grow: 2;
    min-width: 18%;
    cursor: pointer;

    @include break($small ) {
      flex: 2 0 calc(100% / 2);
    }
  }

  &.type-video {
    cursor: pointer;
  }

  &.text-left {
    .video-text {
      left: 0;
    }
  }

  &:hover {
    .image-text {
      opacity: 1;
      z-index: 1;
      transform: translateY(0);
    }
  }
}

.preview {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.text {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: vw(8);
  font-size: vw(14);
  font-weight: 300;
  line-height: 1.67;
  height: 100%;
  background-color: $primary;
  position: absolute;

  @include break($small ) {
    line-height: 1.27;
    font-size: 11px;
    padding: 5px;
  }
}

.image-text {
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 360ms ease-out, transform 360ms ease-out;
  transform: translateY(10%);
}

.video-text {
  position: absolute;
  height: 100%;
  background-color: $primary;
  width: 50%;
  color: #fff;
  opacity: 0.9;
}

.hint {
  position: absolute;
  bottom: 6px;
  z-index: 0;
  left: 10px;
  font-size: 18px;
  color: #fff;
  transform: rotate(45deg);


}
</style>