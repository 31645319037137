<template>
  <div class="tooltip">
    <div class="tooltip__trigger" @mouseenter="toggle" @mouseleave="toggle" @touchenter="toggle" @touchleave="toggle">
      i
    </div>
    <div class="tooltip__content" v-if="visible">{{ content }}</div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  name: "ToolTip",
  props: {
    content: String,
  },
  setup(props) {
    const visible = ref(false);

    const toggle = () => (visible.value = !visible.value);

    return { ...props, visible, toggle };
  },
};
</script>
<style lang="scss">
@import "@/scss/setup";

.tooltip {
  position: relative;

  &__trigger {
    height: vw(16);
    width: vw(16);
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    border: 0.3px solid rgba(#3c3e40, 0.5);
    font-size: vw(14);
    font-weight: bold;
    box-shadow: 0 8px 22px 4px rgba(0, 53, 138, 0.15);
    margin: 0 8px;

    @include break($small) {
      height: 18px;
      width: 18px;
      font-size: 16px;
    }
  }

  &__content {
    position: absolute;
    width: vw(180);
    background-color: #fff;
    padding: vw(10);
    top: 0;
    left: 50%;
    transform: translate(-50%, -140%);
    z-index: 5;
    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.3);
    $arrow-size : 12px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      border-top: $arrow-size solid transparent;
      border-left: $arrow-size*.67 solid transparent;
      border-right: $arrow-size*.67 solid transparent;
    }

    &::before {
      //border-top-color: inherit;
      bottom: $arrow-size * -1;
      height: 0;
      width: 0;
    }

    &::after {
      border-top-color: #fff;
      bottom: calc($arrow-size * -1 + 1px);
      z-index: 1;
    }

    @include break($small) {
      width: 160px;
      padding: 8px;
      left: 0;
    }
  }
}
</style>