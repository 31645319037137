<template>
  <div class="play-wrapper" @click="() => wrapperPlay && play()">
    <button class="play" :class="{ small: small }" @click="play"></button>
  </div>
</template>
<script>
import { useStore } from "vuex";
export default {
  name: "VideoBtn",
  props: {
    url: String,
    small: Boolean,
    wrapperPlay: Boolean,
  },
  setup(props) {
    const store = useStore();

    const play = () => {
      store.commit("setModal", { type: 'video', url: props.url });
    };
    return { play, ...props };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.play-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.play {
  height: vw(75);
  width: vw(75);
  background-color: rgba(255, 255, 255, 0.45);
  border-radius: 50%;
  box-shadow: 0 0 8px 2px rgba(0, 24, 64, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  @include break($small ) {
    height: 48px;
    width: 48px;
  }

  &::after {
    content: "";
    height: 0;
    border: vw(19) solid transparent;
    border-left-color: #fff;
    transform: scaleX(1.6) translateX(30%);

    @include break($small ) {
      border-width: 14px;
    }
  }

  &.small {
    height: vw(30);
    width: vw(30);

    @include break($small ) {
      height: 32px;
      width: 32px;
    }

    &::after {
      border: vw(7.5) solid transparent;
      border-left-color: #fff;
      transform: scaleX(1.6) translateX(30%);

      @include break($small ) {
        border-width: 8px;
      }
    }
  }
}
</style>