<template>
  <main class="main">
    <div class="main__inner">
      <div class="layout">
        <h2 class="small-title small-title--ul">הדמיות מערכת</h2>
        <div class="layout__inner shadow shadow--su">
          <div class="layout__first">
            <div class="top" v-if="isMobile">
              <div class="top__element">
                <img src="@/assets/icon_panels.svg" alt="שמש" />
                <h3 class="top__title">
                  <span>מספר פאנלים</span>
                  <div><strong>{{ numOfPannels }}</strong></div>
                </h3>
              </div>
              <div class="top__element">
                <img src="@/assets/icon_kw.svg" alt="שמש" />
                <h3 class="top__title">
                  <span>הספק מערכת</span>
                  <div><strong>{{ kWp }}</strong> ק״ו </div>
                </h3>
              </div>
            </div>
            <div class="pannel-wrap">
              <div class="pannels">
                <div class="pannels__top">
                  <button class="pannel-head" :class="{ active: pannel.active }" v-for="pannel in pannels"
                    :key="pannel.uid" @click="() => selectPannel(pannel.uid)">
                    <h4 class="pannel-title" :data-target="pannel.uid">
                      {{ pannel.Title }}
                    </h4>
                  </button>
                </div>
                <div class="pannels__body">
                  <div class="pannels__content" :class="{ none: !pannel.active }" v-for="pannel in pannels"
                    :key="pannel.uid" :data-target="pannel.uid">
                    <transition name="fade">
                      <div class="pannels__content-inner" v-if="pannel.active">
                        <img v-if="pannel.MimeType.includes('image')" :src="pannel.Url" alt="" srcset="" />
                        <video controls v-else-if="pannel.MimeType.includes('video')" autoplay loop playsinline>
                          <source :src="pannel.Url" :type="pannel.MimeType" />
                          Your browser does not support the video tag.
                        </video>
                        <Waypoint @change="(data) => wpHandler(data)" />
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="layout__last">
            <div class="top" v-if="!isMobile">
              <div class="top__element">
                <img src="@/assets/icon_panels.svg" alt="שמש" />
                <h3 class="top__title">
                  <span>מספר פאנלים</span>
                  <div><strong>{{ numOfPannels }}</strong></div>
                </h3>
              </div>
              <div class="top__element">
                <img src="@/assets/icon_kw.svg" alt="שמש" />
                <h3 class="top__title">
                  <span>הספק מערכת</span>
                  <div><strong>{{ kWp }} </strong> ק״ו</div>
                </h3>
              </div>
            </div>
            <div class="btn-group">
              <button class="btn btn--primary"
                @click="() => { createDataLayerEvent({ 'event': 'system_simulation', 'learn_more': 'System Installation' }); openModal('installation') }">
                התקנת המערכת
              </button>
              <button class="btn btn--primary"
                @click="() => { createDataLayerEvent({ 'event': 'system_simulation', 'learn_more': 'Technical Spec' }); openModal('hardware') }">
                מפרט טכני
              </button>
              <button v-if="!isMobile" class="btn btn--primary"
                @click="() => { createDataLayerEvent({ 'event': 'system_simulation', 'learn_more': 'Yearly Output Distribution' }); openModal('output') }">
                התפלגות תפוקה שנתית
              </button>
              <button class="btn btn--secondary"
                @click="() => { createDataLayerEvent({ 'event': 'system_simulation', 'learn_more': 'Planning Principles' }); openModal(planningModal) }">
                עקרונות התכנון
              </button>
              <button class="btn btn--empty"
                @click="() => { createDataLayerEvent({ 'event': 'system_simulation', 'learn_more': 'Environmental Impact' }); openModal('environment') }">
                תרומה לסביבה
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { computed, toRef } from "vue";
import { useStore } from "vuex";
import { v4 as uid } from 'uuid'
import { Waypoint } from 'vue-waypoint'
import { createDataLayerEvent } from '@/inc/helpers'

export default {
  name: "ViewPlan",
  components: {
    Waypoint,
  },
  props: { isActive: Boolean },
  setup(props) {
    const store = useStore();
    /**
     * Data
     */
    const {
      userData: {
        ProposalOutput: { QuantityOfPanels: numOfPannels, kWp, RoofType },
        Files: pannels,
      },
    } = store.getters;
    const isMobile = toRef(store.getters, 'isMobile');
    const popups = computed(() => store.getters.popups);
    console.log('us', pannels)

    // RoofType ->  Tiles || Flat || Tiles And Flat 
    const planningModal = RoofType === 'Flat' ? 'planningFlat' : 'planningPitched'
    pannels && pannels.forEach((pannel, i) => (pannel.active = i === 0, pannel.uid = uid()));

    /**
     * Functions
     */
    const selectPannel = (id) => {
      pannels.forEach((pannel) => (pannel.active = pannel.uid === id));
    }

    const openModal = (name) => store.commit("setModal", name);

    const wpHandler = (data) => {
      const video = document.querySelector('video')
      if (data.going == "IN") {
        video.play()
        console.log('PLAY')
      }
      else if (data.going == "OUT") {
        video.pause()
        console.log('PAUSE')
      }
    }

    return {
      numOfPannels,
      kWp,
      pannels,
      selectPannel,
      planningModal,
      openModal,
      isMobile,
      popups,
      props,
      wpHandler,
      createDataLayerEvent
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/setup";

.main {
  background-image: url("@/assets/shape-planing.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 60vw;

  @include break($small) {
    background-image: none;
    padding-bottom: 40px;
  }
}

.layout {
  width: 100%;

  &__inner {
    display: flex;
    width: 100%;
    padding: vw(12, 1920);
    background-color: #fff;

    @include break(1600px) {
      padding: vw(12);
    }

    @include break($small) {
      display: block;
      background-color: inherit;
    }
  }

  &__first {
    flex: 0 0 vw(800);
    margin-left: vw(32);

    @include break($small) {
      margin-left: 0;
    }
  }

  &__last {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: tovw(10px);
  background-color: $dark-bg;
  align-items: center;
  padding: vw(18) vw(10);

  @include break($large) {
    padding-bottom: tovw(10px);
  }

  @include break($small) {
    background: none;
    margin-bottom: 16px;
  }

  &__element {
    @include break($small) {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-weight: normal;
    font-size: vw(18);
    margin-right: vw(6);

    @include break($small) {
      font-size: 4vw;
      text-align: start;
      font-weight: 300;
    }

    div {
      strong {
        font-weight: 700;
        font-size: vw(30, 1920);
        line-height: 1;

        @include break($large) {
          font-size: vw(30);
        }

        @include break($small) {
          font-size: 5vw;
        }
      }
    }
  }

  img {
    @include break($small) {
      height: auto;
      margin-left: 4px;
      width: 15vw;
    }
  }
}

.pannels {
  position: relative;

  &::before {
    @include break($small) {
      content: "";
      display: block;
      width: 8px;
      height: 16px;
      position: absolute;
      top: 12px;
      left: 10px;
      background: url("@/assets/chevron-left.svg") center center no-repeat;
      background-size: contain;
      filter: drop-shadow(0 0 2px #00000055);
    }
  }

  &__top {
    display: flex;
    margin-bottom: vw(10, 1920);
    background-color: #fff;
    overflow: auto;


    @include break($small) {
      background-color: inherit;
      overflow: auto;
    }


  }

  &__body {
    height: vw(510, 1920);

    @include break($large) {
      height: vw(390);
    }

    @include break($small) {
      height: auto;
      object-fit: contain;
      max-width: 100%;
      min-height: 50vw;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }

    video {
      display: block;
      height: 100%;
      margin: 0 auto;
      width: 100%;
      object-fit: cover;

      @include break($small) {
        height: auto;
        width: 100%;
      }
    }
  }

  &__content {
    height: 100%;
  }

  &__content-inner {
    height: 100%;
  }
}

.pannel-head {
  padding: vw(16, 1920) vw(4, 1920);
  flex: 1;
  background-color: $dark-bg;
  box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.05);

  @include break($large) {
    padding: 8px 25px;
  }

  &.active {
    box-shadow: none;
    background: #fff;

    .pannel-title {
      border-bottom: 1.5px solid $secondary;
      font-weight: 600;
    }
  }
}

.pannel-title {
  border-bottom: 1.5px solid transparent;
  color: $primary;
  white-space: nowrap;
  font-weight: 300;
  font-size: vw(18, 1920);
  width: fit-content;
  margin: 0 auto;
  line-height: 1.5;

  @include break($large) {
    font-size: tovw(15px);
  }

  @include break($small) {
    font-size: 16px;
    font-weight: 300;
  }
}

.none {
  display: none;
}

.facts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: tovw(14px);
}

.btn-group {
  .btn {
    display: block;
    width: 100%;

    &:not(:last-child) {
      margin: vw(16) 0;

      @include break($small) {
        margin: 12px 0;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 600ms ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
