<template>
  <main class="main pro">
    <div class="main__inner">
      <h2 class="small-title mobile-only">בטיחות ובילדרים</h2>
      <div class="layout">
        <div class="tabs">
          <div class="top">
            <button :class="{ head: 1, active: tab.active }" v-for="tab in tabs" :key="tab.id"
              @click="() => setActiveTab(tab.id)" :data-text="tab.tab_text">
              <span class="head__inner">
                {{  tab.tab_text  }}
              </span>
            </button>
          </div>
          <div class="pannel">
            <transition name="fade" mode="out-in">
              <div v-if="activeTab">
                <h3 class="pannel__title">{{  activeTab.title  }}</h3>
                <p class="pannel__text">{{  activeTab.content  }}</p>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="img-wrapper">
        <transition v-for="tab in tabs" :key="tab.id" name="fade" mode="out-in">
          <img :class="{ show: tab.id == activeTab.id }" :src="tab.img" rel="preload" />
        </transition>
      </div>
    </div>
  </main>
</template>
<script>
// Core
import { reactive, computed, nextTick } from "vue";
import { useStore } from "vuex";

// External
import { v4 as uid } from "uuid";

export default {
  name: "ViewPro",
  setup() {
    const store = useStore();


    const { items: tabs } = reactive(store.getters.content.pro);
    tabs.forEach((item, index) => {
      item.id = uid();
      item.img = item.image.url;
      item.active = index === 0;
    });

    const activeTab = computed(() => tabs.find((tab) => tab.active) || false);


    const setActiveTab = (id) => {
      tabs.forEach((tab) => (tab.active = false));
      nextTick(() => tabs.forEach((tab) => (tab.active = id == tab.id)));
    };

    return { tabs, activeTab, setActiveTab };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.main {
  @include break($small) {
    background-image: url("@/assets/backgrounds/mobile-bg3.svg");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 30%;
  }

  &__inner {
    @include break($small) {
      flex-direction: column-reverse;
      padding-bottom: 25vw;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.layout {
  width: 100%;
  position: relative;
  margin: 0 5vw;

  @include break($small) {
    width: unset;
  }
}

.small-title {
  width: 100%;
  order: 3;
  margin-bottom: 20px;

  @include break($small) {
    margin: -7vw 0 10vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.tabs {
  width: vw(530);
  box-shadow: 0 8px 22px 4px rgba(0, 53, 138, 0.15);
  position: absolute;
  background-color: $light-bg;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: vw(35) vw(38) vw(55);

  @include break($small) {
    position: relative;
    width: 100%;
    padding: 8px;
    transform: none;
    top: -32px;
    padding: 0;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.4;
  }
}

.top {
  @include break($small) {
    display: flex;
  }
}

.head {
  font-size: vw(20);
  opacity: 0.7;
  transition: .2s all;
  position: relative;

  @include break($small) {
    font-size: 18px;
    background-color: #fff;
    flex: 0 0 33.333%;
    padding: 8px;
    background-color: $dark-bg;
  }

  &::before {
    display: block;
    content: attr(data-text);
    font-weight: 600;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
  }

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    background-color: #f7be2f;
    transition: .2s all;
  }

  &:hover {
    opacity: 1;

    &::after {
      width: 70%;
    }
  }

  &:not(:last-child) {
    margin-left: vw(70);

    @include break($small) {
      margin-left: 0;
    }
  }

  &.active {
    opacity: 1;
    font-weight: bold;

    &::after {
      width: 100%;
    }

    @include break($small) {
      background-color: #fff;
    }

    .head__inner {
      //border-color: $secondary;
    }
  }

  &__inner {
    display: inline-block;
    border-bottom: 2px solid transparent;
  }
}

.pannel {
  @include break($small) {
    background-color: #fff;
    padding: 16px 16px 22px;
    transition-delay: height 1s;

  }

  &__title {
    font-size: vw(18);
    font-weight: 600;
    margin: vw(25) 0 vw(14);

    @include break($small) {
      font-size: 18px;
    }
  }

  p {
    font-size: vw(18);

    @include break($small) {
      font-size: 16px;
    }
  }
}

.img-wrapper {
  position: absolute;
  left: 0;
  width: 60vw;
  max-width: 70vw;
  top: 0;
  height: 100%;


  @include break($small) {
    position: relative;
    height: 64vw;
    width: 100%;
    max-width: 100vw;
  }

  img {
    opacity: 0;
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: .5s ease-in-out;


    @include break($small) {
      object-fit: cover;
      margin: 0 auto;
      max-width: initial;
      width: 100%;
    }

    &.show {
      opacity: 1;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 380ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>