<template>
  <div class="main">

    <div class="main__inner">
      <div class="layout" :class="{ single: BusinessModel != 'PurchaseLeasing' }">
        <h2 v-if="isMobile" class="small-title proposal-title">הצעת מחיר</h2>
        <div class="mobile-only mobile-top switch">
          <button v-if="BusinessModel != 'Leasing'" @click="() => (showFirst = true)" :class="{ active: showFirst }">
            <span>מודל רכישה</span>
          </button>
          <button v-if="BusinessModel != 'Purchase'" @click="() => (showFirst = false)"
            :class="{ active: !showFirst || BusinessModel == 'Leasing'}">
            <span>מודל ליסינג</span>
          </button>
        </div>
        <div class="layout__col" :class="{ single: BusinessModel == 'Purchase' }"
          v-if="(showFirst || !isMobile) && BusinessModel != 'Leasing'">
          <h2 class="small-title small-title--ul desktop-only">מודל רכישה</h2>
          <toggle-children :data="buyingData"></toggle-children>
        </div>
        <div class="layout__col" :class="{ single: BusinessModel == 'Leasing' }"
          v-if="(!showFirst || !isMobile || BusinessModel == 'Leasing') && BusinessModel != 'Purchase'">
          <h2 class="small-title small-title--ul desktop-only">מודל ליסינג</h2>
          <toggle-children :data="leasingData" :isLeasing="true"></toggle-children>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Core
import { markRaw, shallowRef, ref } from "vue";
import { useStore } from "vuex";
import { v4 as uid } from "uuid";

// Components
import ToggleChildren from "@/components/Toggle.vue";
import IncomeChart from "@/components/IncomeChart.vue";
import LeasingContent from "@/components/LeasingContent.vue";
import BuyingContent from "@/components/BuyingContent.vue";


export default {
  name: "ViewModel",
  components: {
    ToggleChildren,
  },
  setup() {
    const buyingData = [
      {
        title: "רכישה",
        component: shallowRef(markRaw(BuyingContent)),
        id: uid(),
      },
      {
        title: "גרף הכנסות",
        component: shallowRef(markRaw(IncomeChart)),
        id: uid(),
      },
    ];
    const leasingData = [
      {
        title: "ליסינג",
        component: shallowRef(markRaw(LeasingContent)),
        id: uid(),
      },
      {
        title: "גרף הכנסות",
        component: shallowRef(markRaw(IncomeChart)),
        id: uid(),
      },
    ];

    const store = useStore();
    const { isMobile } = store.getters;
    const showFirst = ref(true);
    const { LeasingSteps } = store.getters.userData
    const { BusinessModel } = store.getters.userData.ProposalOutput

    return {
      buyingData,
      leasingData,
      isMobile,
      showFirst,
      LeasingSteps,
      BusinessModel,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.main {
  background-image: url("@/assets/shape-model.svg");
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 60vw;

  &__inner {
    padding: 0 vw(100, 1920);

    @include break($large) {
      padding: 0 vw(64);
    }

    @include break($small) {
      background-color: #fff;
      padding: 0;
    }
  }
}

.layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;

  @include break($small) {
    display: block;
  }

  &.single {
    justify-content: center;
  }

  &__col {
    flex: 0 1 calc(50% - 12px);

    @include break($small) {
      background-color: #fff;
      padding: 12px 20px 32px;
    }

    &.single {
      flex-basis: 60%;

      @include break($small) {
        height: unset;
        max-height: unset;
        padding-top: 4px;
      }
    }
  }
}

.proposal-title {
  background-color: $light-bg;
  padding: 12px 5vw 24px;

  @include break($small) {
    padding-top: 30px;
  }
}

.mobile-top {


  @include break($small) {
    display: flex;
    box-shadow: 4px 4px 20px 1px rgba(0, 53, 138, 0.2);
  }

  button {
    flex: 0 1 100%;
    background-color: $light-bg;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.05);
    color: rgba(60, 62, 64, 0.7);

    &.active {
      background-color: #fff;
      box-shadow: none;
      color: #00358a;

      span {
        border-bottom: 2px solid #fbc02d;
        font-weight: bold;
      }
    }
  }
}
</style>