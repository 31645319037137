<template>
  <main class="main about">
    <div class="main__inner">
      <div class="layout">
        <h2 class="small-title mobile-only">מי זאת וולטה?</h2>
        <div class="top">
          <p class="top__text">
            <span class="top__bg">
              {{ IsGroup ? about.group_text : about.text }}
            </span>
          </p>
          <div class="top__media">
            <img :src="IsGroup ? about.group_image?.url : about.image?.url" alt="" />
            <video-btn :url="IsGroup ? about.group_video : about.video" />
          </div>
        </div>
        <div class="facts">
          <fact-box v-for="(fact, index) in about.items" :key="index" :number="+fact.number" :imgUrl="fact.icon?.url"
            :text="fact.text" :flex="true" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// Core
import { useStore } from "vuex";

// Components
import FactBox from "@/components/FactBox.vue";
import VideoBtn from "@/components/VideoBtn.vue";

export default {
  name: "ViewAbout",
  components: {
    FactBox,
    VideoBtn,
  },
  setup() {
    const store = useStore();
    const about = store.getters.content.about;
    const { IsGroup } = store.getters.userData.ProposalOutput;

    return { about, IsGroup };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/setup";

.main {
  background-image: url("@/assets/backgrounds/shape-about.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  z-index: -1;

  @include break($small) {
    background-image: url("@/assets/backgrounds/mobile-bg1.svg");
    background-position: top left;
    background-size: 20%;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: vw(16);

  @include break($small) {
    display: block;
    margin-bottom: 0;
  }

  &__media {
    flex: 0 0 50%;
    height: vw(277);
    box-shadow: 0 8px 22px 4px rgba(0, 53, 138, 0.15);
    position: relative;

    @include break($small) {
      height: 160px;
      margin-top: 8px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__bg {
    background-color: $primary-light;
    display: block;
    padding: 4px;
  }

  &__text {
    font-size: vw(22, 1920);
    padding-left: vw(50, 1920);
    padding-right: vw(50, 1920);
    line-height: 1.4;
    color: #fff;

    @include break($large) {
      font-size: vw(17);
      padding-left: vw(50);
    }

    @include break($small) {
      font-size: 16px;
      background-color: $primary-light;
      color: #fff;
      padding: 8px;
      margin-top: 20px;
      font-weight: 300;
      padding-bottom: 20px;
    }
  }
}

.facts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include break($medium) {}

  >* {
    flex: 0 0 calc(25% - vw(16));
    background-color: $light-bg;

    @include break($medium) {}

    @include break($small) {
      flex: 0 0 calc(50% - 4px);
      margin-top: 8px;
    }
  }
}
</style>