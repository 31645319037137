import { createStore } from 'vuex'
import axios from 'axios'
/** Parts */
import steps from './steps'
import modals from './modals'
/** Helpers */
import { dataApiUrl, contactApiUrl, contentApiUrl } from './constants'
import { getProposalIdFromUrl } from '@/inc/helpers'

export default createStore({
  state: {
    steps,
    modals,
    activeModal: null,
    userData: null,
    mainSlider: null,
    content: null,
    currentVideo: null,
    isMobile: window.innerWidth <= 990,
    currentStep: null,
    apiError: null,
    showContactMessage: false,
    currentLeasing: null
  },
  getters: {
    userData: (state) => state.userData,
    modals: ({ modals }) => modals,
    activeModal: ({ activeModal }) => activeModal,
    isMobile: (state) => state.isMobile,
    popups: ({ content }) => content && ({
      installation: content.popup_installation,
      hardware: content.popup_hardware,
      output: content.popup_output,
      environment: content.popup_environment,
      planningFlat: content.planning_flat,
      planningPitched: content.planning_pitched,
      warranty: content.warranty,
      maintenance: content.maintenance,
      contact: content.contact,
    }),
    content: (state) => state.content,
    activeModalContent: (state, getters) => getters.popups[getters.activeModal.name],
    currentVideo: (state) => state.currentVideo,
    hasMixedRoof: state => state.userData.ProposalOutput.RoofType === 'Tiles And Flat',
    activeStepName: state => state.steps.find(step => step.active)?.name,
    activeStepIndex: state => state.steps.find(step => step.active)?.step,
  },
  mutations: {
    setStep(state, index) {
      const active = state.steps.find(el => el.active)
      console.log('active', active, 'index', index)
      if (active.step === index) return

      state.steps.find(el => el.active).active = false
      state.steps.find(el => el.step === index).active = true
      state.currentStep = state.steps.find(el => el.step === index)
      state.mainSlider && state.mainSlider.slideTo(index)

      document.querySelector('.swiper-button-next').classList.add('stepped')

      window.history.replaceState(null, null, '#' + index)


    },
    setUserData(state, data) {
      state.userData = data
    },
    setContent(state, data) {
      state.content = data
    },
    setModal(state, payload) {
      const name = typeof payload === 'string' ? payload : payload.type
      if (name === 'video')
        state.currentVideo = payload.url

      let activeModal = state.modals.find(modal => modal.active)
      if (activeModal) activeModal.active = false

      activeModal = state.modals.find(modal => modal.name === name)
      activeModal.active = true
      state.activeModal = activeModal

      document.body.classList.add("modal-open");
      if (state.isMobile) {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;
      }

    },
    closeModal(state) {
      const active = state.modals.find(modal => modal.active)
      if (active) active.active = false
      state.currentVideo = ''
      state.activeModal = null

      document.body.classList.remove("modal-open");
      if (state.isMobile) {
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }

    },
    setIsMobile(state, url) {
      state.currentVideo = url
      state.isMobile = window.innerWidth <= 990
    },
    apiError(state, type) {
      state.apiError = { type }
    },
    contactRequestSuccess(state) {
      state.showContactMessage = true
    },
    setCurrentLeasing(state, selectedProgram) {
      state.currentLeasing = state.userData.LeasingSteps.find(el => el.Step == selectedProgram.step && el.Delay == selectedProgram.delay)
      console.log('curr', state.currentLeasing)
    }

  },
  actions: {
    // For Async
    fetchUserData({ commit }) {
      const proposalId = getProposalIdFromUrl()
      axios.get(dataApiUrl, {
        params: { ProposalID: proposalId }
      })
        .then((res) => {
          console.log('RES', res)
          if (!res?.data?.DataForCustomer?.length) {
            throw new Error('No User Data')
          }
          console.log('Remote Data', res)
          commit('setUserData', res.data)
        })
        .catch((err) => {
          console.error(err)
          // axios.get('data.json')
          //   .then(res => {
          //     commit('setUserData', res.data)
          //   })

          commit('apiError', 'user')
        });

    },
    fetchContent({ commit }) {
      axios.get(contentApiUrl)
        .then(res => {
          if (!res || !res.data) {
            throw Error('No Content')
          } else {
            commit('setContent', res.data)
            console.log('Content', res.data)
          }

        })
        .catch((err) => {
          console.error(err)
          commit('apiError', 'content')
        });
    },
    sendContactRequest({ commit }) {
      const proposalId = getProposalIdFromUrl()
      axios.get(contactApiUrl, {
        params: { ProposalID: proposalId }
      })
        .then(res => {
          console.log('res', res)
          if (!res) {
            throw Error('Default ERROR')
          } else {
            commit('contactRequestSuccess')
          }

        })
        .catch((err) => {
          console.error(err)
          commit('apiError', 'content')
        });
    }
  },
  modules: {
  },

})